import { Injectable } from "@angular/core";
import { RegisterRepository } from "../repository/register.repository";
import { IServiceRegistration } from "../../shared/model/service-registration.model";
import {map, Observable} from "rxjs";
import { Store, select } from "@ngrx/store";
import { getIsRegisteringSelector, getRegistationResultSelector, getRegistrationError } from "../store/register/register.selectors";
import {IRetryData} from "../../shared/model/retry-data.model";

@Injectable({ providedIn: 'root' })
export class RegisterService {
    constructor(private registerRepo: RegisterRepository, private store: Store) { }

    public registerCSERCourseInBanner(serviceRegistration: IServiceRegistration): Observable<string> {
        return this.registerRepo.registerCSERCourseInBanner(serviceRegistration);
    }

    public hasPendingRegistrations() {
      return this.registerRepo.hasPendingRegistrations();
    }

    public retryRegistration() {
      return this.registerRepo.retryRegistration();
    }

    public getRegistrationResult(): Observable<string | undefined> {
        return this.store.pipe(select(getRegistationResultSelector));
    }

    public getRegistrationError(): Observable<string | undefined> {
        return this.store.pipe(select(getRegistrationError));
    }

    public getIsRegistering(): Observable<boolean> {
        return this.store.pipe(select(getIsRegisteringSelector));
    }

    public getFailedRegistrations(): Observable<IRetryData[]> {
        return this.registerRepo.getFailedRegistrations();
    }

    public retryFailedRegistration(retryData: IRetryData): Observable<IRetryData> {
        return this.registerRepo.retryFailedRegistration(retryData)
    }
}
