import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import {IServiceRegistration} from "../../shared/model/service-registration.model";
import {catchError, Observable, of} from "rxjs";
import {IRetryData} from "../../shared/model/retry-data.model";

const BASE_URL: string = `${environment.apiUrl}/register`
@Injectable({ providedIn: 'root' })
export class RegisterRepository {
    constructor(private http: HttpClient) { }

    public registerCSERCourseInBanner(serviceRegistration: IServiceRegistration): Observable<string> {
        return this.http.post<string>(`${BASE_URL}`, serviceRegistration, { responseType: 'text' as 'json' });
    }

    public hasPendingRegistrations(): Observable<boolean> {
      return this.http.get<boolean>(`${BASE_URL}/hasPendingRegistrations`);
    }

  public retryRegistration(): Observable<number> {
      return this.http.get<number>(`${BASE_URL}/retryRegistrations`);
  }

  public getFailedRegistrations(): Observable<IRetryData[]> {
    return this.http.get<IRetryData[]>(`${BASE_URL}/failed-registrations`).pipe(
      catchError(err => { console.log(err); return of(err) })
    );
  }

  public retryFailedRegistration(retryData: IRetryData): Observable<IRetryData> {
    return this.http.post<IRetryData>(`${BASE_URL}/retry-failed-registration`, retryData)
  }
}
